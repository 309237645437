<script setup lang="ts">
definePageMeta({
  title: 'Rates',
  breadcrumb: 'Rates Management',
})

const router = useRouter()
const sanctumFetch = useSanctumClient()
const hotelId = computed(() => Number(useRoute().params.id))

const { data: hotel } = useAsyncData('rates/units', async () => await sanctumFetch(`/api/v1/settings/hotels/${hotelId.value}`, {
  params: {
    include: 'units',
  },
}))

provide('rates', {
  hotelId,
  hotel,
})
</script>

<template>
  <DashboardPage>
    <UCard>
      <template #header>
        <div class="flex items-center justify-between">
          <div class="flex flex-col leading-5 space-y-0.5">
            <div class="flex items-center gap-2">
              <h2 class="text-lg font-semibold tracking-tight">
                Rate Management
              </h2>
              <UDivider
                orientation="vertical"
                icon="lucide:chevrons-right"
                :ui="{
                  container: {
                    vertical: 'my-0',
                  },
                }"
              />
              <span class="text-sm italic text-gray-400">{{ hotel?.name }} ({{ hotel?.alias }})</span>
            </div>
            <p class="text-sm text-gray-500">
              Set daily rates for each room type. Weekend rates are highlighted.
            </p>
          </div>

          <UButton
            variant="soft"
            size="sm" icon="lucide:arrow-left"
            @click="() => router.push('/settings?content=hotels')"
          >
            Back
          </UButton>
        </div>
      </template>

      <RateManagment />
    </UCard>
  </DashboardPage>
</template>

<style scoped>
/* Add your styles here */
</style>
