<script setup lang="ts">
import type { array } from 'zod'
import {
  addDays,
  eachDayOfInterval,
  format,
  isToday,
  isWeekend,
} from 'date-fns'

const toast = useToast()
const currentMonth = ref(new Date())
const displayDays = ref(14) // Number of days to display at once

// Generate dates for the calendar
const calendarDates = computed(() => {
  const start = new Date(currentMonth.value)
  return eachDayOfInterval({
    start,
    end: addDays(start, displayDays.value - 1),
  })
})

const filterBetween = computed(() => {
  const start = new Date(currentMonth.value)
  const end = addDays(start, displayDays.value - 1)
  return `${format(start, 'yyyy-MM-dd')},${format(end, 'yyyy-MM-dd')}`
})

const route = useRoute()
const sanctumFetch = useSanctumClient()
const hotelId = ref(route.params.id)

const { hotel } = inject('rates', {
  hotel: computed(() => null),
})

const rooms = computed(() => hotel.value?.units || [])

const { data: roomRates, pending: ratesPending, refresh } = useLazyAsyncData('rates/rates', async () => await sanctumFetch(`/api/v1/settings/rate-managment`, {
  method: 'POST',
  params: {
    'filter[hotel_id]': hotelId.value,
    'filter[starts_between]': filterBetween.value,
  },
}), {
  watch: [currentMonth],
  default: () => ({}),
})

// Update rates when month or display days change
watch([currentMonth, displayDays], () => {

})

// Navigation functions
function nextPeriod() {
  currentMonth.value = addDays(currentMonth.value, displayDays.value)
}

function prevPeriod() {
  if (isToday(currentMonth.value)) {
    return
  }

  currentMonth.value = addDays(currentMonth.value, -displayDays.value)
}

async function updateRate(roomId, dateKey, event: InputEvent) {
  const value = Number((event.target as HTMLInputElement).value)
  const { data, message } = await sanctumFetch(`/api/v1/settings/rate-managment/${roomId}/${dateKey}`, {
    method: 'POST',
    body: {
      rate: value,
    },
  })

  consoleInfo('Rate updated', data)

  toast.add({
    title: 'Rate updated',
    description: message,
    color: 'green',
    icon: 'lucide:radar',
    timeout: 2000,
  })
  refresh()
}

function applyToday() {
  currentMonth.value = new Date()
}
</script>

<template>
  <div class="rate-management">
    <div class="controls mb-4 flex items-center justify-between flex-wrap gap-2">
      <div class="period-navigator flex gap-2 items-center text-sm">
        <UButton
          icon="i-heroicons-chevron-left"
          color="gray"
          variant="soft"
          @click="prevPeriod"
        />
        <span class="font-medium">
          {{ format(currentMonth, 'MMMM d, yyyy') }} -
          {{ format(addDays(currentMonth, displayDays - 1), 'MMMM d, yyyy') }}
        </span>
        <UButton
          icon="i-heroicons-chevron-right"
          color="gray"
          variant="soft"
          @click="nextPeriod"
        />

        <UButton
          color="blue"
          variant="soft"
          icon="i-heroicons-calendar"
          :disabled="isToday(currentMonth)"
          class="disabled:bg-gray-200 disabled:text-gray-400 disabled:opacity-55"
          @click="applyToday"
        >
          Today
        </UButton>
      </div>

      <div class="controls flex gap-4 items-center text-gray-600">
        <div class="flex gap-1 items-center">
          <div class="size-4 bg-blue-200 ring-1" />
          <label for="_today" class="text-sm font-medium">Today</label>
        </div>

        <div class="flex gap-1 items-center">
          <div class="size-4 bg-amber-50 ring-amber-400 ring-1" />
          <label for="_today" class="text-sm font-medium">Weekend</label>
        </div>
      </div>
    </div>

    <div class="rate-grid-container overflow-x-auto">
      <div class="rate-grid border-l border-t border-r-0 min-w-full">
        <!-- Header row with dates -->
        <RateHeader :calendar-dates="calendarDates" />

        <template v-if="ratesPending">
          <div v-for="i in rooms.length || 3" :key="i" class="grid grid-cols-[200px_repeat(auto-fill,minmax(90px,1fr))] gap-2 p-2">
            <USkeleton v-for="r in 12" :key="r" class="h-6" />
          </div>
        </template>

        <template v-else-if="!rooms.length">
          <div class="flex items-center justify-center p-4 text-gray-500 min-h-40 border-b border-r">
            No rooms available for this hotel.
          </div>
        </template>

        <template v-else>
          <!-- Room rows -->
          <div
            v-for="room in rooms"
            :key="room.id"
            class="grid grid-cols-[200px_repeat(auto-fill,minmax(90px,1fr))] sm:grid-cols-[170px_repeat(auto-fill,minmax(80px,1fr))] 2xl:sm:grid-cols-[180px_repeat(auto-fill,minmax(90px,1fr))]"
          >
            <div class="relative p-2 font-medium border-b border-r dark:border-gray-700 bg-white dark:bg-gray-900 leading-5">
              {{ room.name }}
              <div class="text-xs font-normal text-zinc-400">
                Base Rate: ${{ room.baseRate }}
              </div>
              <UPopover
                :popper="{ placement: 'bottom-start' }"
                :ui="{
                  popper: {
                    offset: 2,
                  },
                }"
                class="absolute top-2 right-2"
              >
                <UButton
                  icon="lucide:zap"
                  color="blue"
                  variant="ghost"
                  size="xs"
                  class="absolute top-0 right-0"
                  :ui="{
                    base: 'text-gray-500',
                    size: {
                      xs: 'text-sm',
                    },
                    padding: {
                      xs: 'px-1.5',
                    },
                  }"
                />
                <template #panel>
                  <LazyRateBulkUpdate :room="room" />
                </template>
              </UPopover>
            </div>
            <div
              v-for="date in calendarDates"
              :key="format(date, 'yyyy-MM-dd')"
              class="flex items-center text-center p-1.5 border-b border-r dark:border-gray-700"
              :class="[
                isToday(date) ? 'bg-blue-200' : isWeekend(date) ? 'bg-amber-50 dark:bg-amber-900/10' : 'bg-white',
              ]"
            >
              <!-- pre class="text-[10px]">{{ format(date, 'yyyy-MM-dd') }}</pre -->

              <UInput
                v-if="roomRates[room.id][format(date, 'yyyy-MM-dd')] !== undefined"
                v-model="roomRates[room.id][format(date, 'yyyy-MM-dd')]"
                type="text"
                class="w-full text-center bg-transparent  dark:border-gray-700 rounded"
                size="xs"
                :disabled="ratesPending"
                :ui="{
                  base: 'text-center',
                  size: {
                    xs: 'text-sm',
                  },
                  padding: {
                    xs: 'px-1.5',
                  },
                }"
                @keypress.enter="updateRate(room.id, format(date, 'yyyy-MM-dd'), $event)"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.rate-grid-container {
  max-width: 100%;
  overflow-x: auto;
}

.rate-grid {
  border-collapse: collapse;
}

input[type="number"] {
  appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
